import React, { useEffect, useState } from "react";

function FoodMenuItem({ title, name, description, price1, price2, price3 }) {
  if (title.toLowerCase() === "pice") {
    return (
      <>
        <div key={"pice"} className='menu-restaurant pizza-menu'>
          <span className='clearfix'>
            <span className='menu-title'>{name}</span>
            <span
              style={{ left: "166px", right: "170px" }}
              className='menu-line'></span>
            <span className='menu-price price1'>{price1}€</span>
            <span className='menu-price price2'>{price2}€</span>
            <span className='menu-price price3'>{price3}€</span>
            {name.toLowerCase() === "klasika (g,m)" ? (
              <div className='pizza-size'>
                <span>Mala</span>
                <span>Srednja</span>
                <span>Velika</span>
              </div>
            ) : null}
          </span>
          <span className='menu-subtitle'>{description}</span>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div key={name} className='menu-restaurant'>
          <span className='clearfix'>
            <span className='menu-title'>{name}</span>
            <span
              style={{ left: "166px", right: "44px" }}
              className='menu-line'></span>
            <span className='menu-price'>{price1}€</span>
          </span>
          <span className='menu-subtitle'>{description}</span>
        </div>
      </>
    );
  }
}

export default FoodMenuItem;
