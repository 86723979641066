import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import db from "../../firebase";
import CollapsibleDaily from "./CollapsibleDaily";

const DailyItems = () => {
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [orderNumber, setOrderNumber] = useState(0);
  const [items, setItems] = useState([]);
  useEffect(() => {
    db.collection("dnevna_ponudba")
      .orderBy("order_number", "asc")
      .onSnapshot((snapshot) => {
        setItems(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            title: doc.data().title,
            price: doc.data().price,
            order_number: doc.data().order_number,
          }))
        );
      });
  }, []);

  const addElement = (e) => {
    e.preventDefault();
    if (title) {
      db.collection("dnevna_ponudba")
        .add({
          title: title,
          price: price,
          order_number: orderNumber,
        })
        .then(() => {
          toast.success("Dnevna ponudba uspešno spremenjena!");
          setTitle("");
          setPrice(0);
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      toast.error("Polje je prazno.");
    }
  };
  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <form onSubmit={addElement} className='form__jedilnik form__daily'>
            <input
              type='text'
              value={title}
              placeholder='Dnevna ponudba...'
              onChange={(e) => setTitle(e.target.value)}
            />
            <label>Cena:</label>
            <input
              type='number'
              step='0.01'
              className='price'
              value={price}
              min='0'
              onChange={(e) => setPrice(e.target.value)}
            />
            <label>Zaporedna številka:</label>
            <input
              type='number'
              className='order_number'
              value={orderNumber}
              min='0'
              onChange={(e) => setOrderNumber(e.target.value)}
            />
            <button type='submit' className='btn btn-primary'>
              Dodaj
            </button>
          </form>
        </div>
      </div>
      <div className='row collapsible'>
        <ul className='collapsible-list'>
          {items.map(({ id, title, price, order_number }) => (
            <CollapsibleDaily
              key={id}
              title={title}
              price={price}
              order_number={order_number}
              id={id}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default DailyItems;
