import React, { useState, useEffect } from "react";
import Collapsible from "./Collapsible";
import db from "../../firebase";
import { toast } from "react-toastify";
import DailyItems from "./DailyItems";
import firebase from "firebase";

function Meni() {
  const [title, setTitle] = useState("");
  const [items, setItems] = useState([]);

  useEffect(() => {
    db.collection("hrana")
      .orderBy("order_number", "asc")
      .onSnapshot((snapshot) => {
        setItems(
          snapshot.docs.map((doc) => ({ id: doc.id, title: doc.data().title }))
        );
      });
  }, []);

  const addElement = (e) => {
    e.preventDefault();
    if (title) {
      db.collection("hrana")
        .add({
          title: title,
          order_number: 130,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          toast.success("Nova vrsta hrane uspešno dodana.");
          setTitle("");
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      toast.error("Polje je prazno.");
    }
  };

  return (
    <>
      <h2>Dnevna ponudba</h2>
      <DailyItems />

      <h2>Ustvari novo vrsto jedi:</h2>
      <div className='row'>
        <div className='col-12'>
          <form onSubmit={addElement} className='form__jedilnik'>
            <input
              type='text'
              value={title}
              placeholder='Jedi po domače, Testenine,...'
              onChange={(e) => setTitle(e.target.value)}
            />
            <button type='submit' className='btn btn-primary'>
              Dodaj
            </button>
          </form>
        </div>
      </div>
      <div className='row'>
        {items.map(({ id, title }) => (
          <Collapsible key={id} title={title} itemId={id} />
        ))}
      </div>
    </>
  );
}

export default Meni;
