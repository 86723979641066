import React from 'react'

function DailyFoodMenuItem({name,price}) {
    return (
        <div className='menu-restaurant daily'>
            <span className='clearfix'>
            <a href='#' className='menu-title' >
                {name}
            </a>
            <span
                style={{ left: "166px", right: "44px" }}
                className='menu-line'></span>
            <span className='menu-price'>{price}€</span>
            </span>
        </div>
    )
}

export default DailyFoodMenuItem
