import React, { useEffect, useState } from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Location from "./Location";
import OpenTimeModal from './modals/OpenTimeModal';

function Contact() {
    const [showModal, setShowModal] = useState(false);

    const openModal = () =>{
        setShowModal(true);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="flex-container">

            {/* Contact Box */}
            <div className="left-div">
                {/* Card Box */}
                <div className="card-box">
                    {/* Card Header */}
                    <div className="card-header">
                        <h3 className="card-title">Kontaktiraj nas:</h3>
                    </div>
                    {/* Card Body */}
                    <div className="card-body">
                        <div className="ul-location ul">
                            <LocationOnIcon /><br />
                            <span><span>Pohorska cesta 37</span><br /><span>2380 Slovenj Gradec</span></span>
                        </div>
                        <div className="ul-phone ul">
                            <PhoneIcon /><br />
                            <a className="link" href="tel:02-884-41-40"><span>(02) 884 41 40</span></a><br />
                            <a className="link" href="tel:+386-40-624-914"><span>+386 40 624 914</span></a>
                        </div>
                        <div className="ul-mail ul">
                            <EmailIcon /><br />
                            <a className="link" href="mailto:rest.presa@gmail.com"><span>rest.presa@gmail.com</span></a>
                        </div>
                    </div>
                    {/* Card Footer */}
                    <div>
                        <button className="btn-custom" onClick={openModal}>Odpiralni čas</button>
                        {/* Include Modal */}
                        <OpenTimeModal showModal={showModal} setShowModal={setShowModal} />
                    </div>
                </div>
            </div>

            {/* Google Maps */}
            <div className="right-div">
                <div className="leaflet-container">
                    <Location />
                </div>
            </div>
        </div>
    )
}

export default Contact
