import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { toast } from "react-toastify";
import db from "../../firebase";
import DeleteConfirmationDaily from "./DeleteConfirmationDaily";

const CollapsibleDaily = ({ id, title, price, order_number }) => {
  const [stateTitle, setTitle] = useState("");
  const [statePrice, setPrice] = useState(0);
  const [stateOrder, setOrderNumber] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const [onDelete, setOnDelete] = useState(false);

  useEffect(() => {
    setTitle(title);
    setPrice(price);
    setOrderNumber(order_number);
  }, [title, price, order_number]);

  const updateElement = () => {
    db.collection("dnevna_ponudba")
      .doc(id)
      .update({
        title: stateTitle,
        price: statePrice,
        order_number: stateOrder,
      })
      .then(() => {
        toast.success("Dnevna ponudba uspešno posodobljena.");
        setIsEditable(false);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const showOnDelete = () => {
    if (isEditable === true) {
      toast.warning(
        "Če želite ta izdelek izbrisati, morate najprej zaključiti z urejanjem."
      );
    } else {
      setOnDelete(true);
    }
  };

  return (
    <>
      <li className='daily-item'>
        <div className='form-inside-flex'>
          <label>Ime:</label>
          <input
            type='text'
            value={stateTitle}
            disabled={!isEditable}
            className='title'
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className='form-inside-flex'>
          <label>Cena:</label>
          <input
            type='number'
            step='0.01'
            value={statePrice}
            disabled={!isEditable}
            className='price'
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div className='form-inside-flex'>
          <label>Zaporedna številka:</label>
          <input
            type='number'
            value={stateOrder}
            disabled={!isEditable}
            className='order_number'
            onChange={(e) => setOrderNumber(e.target.value)}
          />
        </div>
        <div className='buttons'>
          {isEditable ? (
            <SaveIcon className='save-icon' onClick={updateElement} />
          ) : (
            <EditIcon
              className='edit-icon'
              onClick={() => setIsEditable(true)}
            />
          )}
          <DeleteForeverIcon
            onClick={() => showOnDelete()}
            className='delete-icon'
          />
        </div>
      </li>
      {onDelete ? (
        <DeleteConfirmationDaily
          id={id}
          onDelete={onDelete}
          setOnDelete={setOnDelete}
        />
      ) : null}
    </>
  );
};

export default CollapsibleDaily;
