import React, { useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9999;
`;

const ModalWrapper = styled.div`
  width: 100%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  position: relative;
  z-index: 10;
  width: 100%;
  @media (min-width: 500px) {
    border-radius: 10px;
  }
`;
function OpenTimeModal({ showModal, setShowModal }) {
  const modalRef = useRef();

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: 1,
    transform: `translateY(0%)`,
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return (
    <>
      {showModal ? (
        <Background ref={modalRef} onClick={closeModal}>
          <animated.div className='opentime-animation' style={animation}>
            <ModalWrapper showModal={showModal}>
              <div className='content-modal opentime-modal'>
                <div className='m-header'>
                  <h5>Odpiralni čas:</h5>
                  <CloseIcon
                    aria-label='Close modal'
                    onClick={() => setShowModal(!showModal)}
                  />
                </div>
                <div className='m-body'>
                  <ul>
                    <li>
                      <span>Ponedeljek:</span>
                      <br />
                      <span>7:00 - 22:00</span>
                    </li>
                    <li>
                      <span>Torek:</span>
                      <br />
                      <span>7:00 - 22:00</span>
                    </li>
                    <li>
                      <span>Sreda:</span>
                      <br />
                      <span>7:00 - 22:00</span>
                    </li>
                    <li>
                      <span>Četrtek:</span>
                      <br />
                      <span>7:00 - 22:00</span>
                    </li>
                    <li>
                      <span>Petek:</span>
                      <br />
                      <span>7:00 - 22:00</span>
                    </li>
                    <li>
                      <span>Sobota:</span>
                      <br />
                      <span>7:00 - 22:00</span>
                    </li>
                    <li>
                      <span>Nedelja:</span>
                      <br />
                      <span>9:00 - 22:00</span>
                    </li>
                  </ul>
                  <ul id='opentime-closetime'>
                    <li>Zaprto:</li>
                    <li>Novo leto</li>
                    <li>Velika noč</li>
                    <li>Dan spomina na mrtve</li>
                    <li>Predbožični večer: 8:00-16:00</li>
                    <li>Božič</li>
                    <li>Silvestrovo: 8:00-16:00</li>
                  </ul>
                </div>
              </div>
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}

export default OpenTimeModal;
