import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { toast } from "react-toastify";
import DeleteConfirmationProduct from "./DeleteConfirmationProduct";
import db from "../../firebase";
import firebase from "firebase";

function CollapsibleBox({
  id,
  title,
  desc,
  name,
  price1,
  price2,
  price3,
  order_number,
  itemId,
}) {
  const [isPizza, setIsPizza] = useState(false);
  const [stateName, setName] = useState("");
  const [stateDesc, setDesc] = useState("");
  const [statePrice1, setPrice1] = useState(0);
  const [statePrice2, setPrice2] = useState(0);
  const [statePrice3, setPrice3] = useState(0);
  const [stateOrderNumber, setOrderNumber] = useState(0);

  const [onDelete, setOnDelete] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    setName(name);
    setDesc(desc);
    setPrice1(price1);
    setPrice2(price2);
    setPrice3(price3);
    setOrderNumber(order_number);
    if (title.toLowerCase() === "pice") {
      setIsPizza(true);
    }
  }, [name, desc, price1, price2, price3, title, order_number]);

  const showOnDelete = () => {
    if (isEditable === true) {
      toast.warning(
        "Če želite ta izdelek izbrisati, morate najprej zaključiti z urejanjem."
      );
    } else {
      setOnDelete(true);
    }
  };

  const updateElement = () => {
    setIsEditable(false);
    db.collection("hrana").doc(itemId).collection("izdelki").doc(id).update({
      name: stateName,
      description: stateDesc,
      price1: statePrice1,
      price2: statePrice2,
      price3: statePrice3,
      order_number: stateOrderNumber,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  return (
    <>
      {isPizza ? (
        <li>
          <div className='form-inside-flex'>
            <label>Ime:</label>
            <input
              type='text'
              value={stateName}
              disabled={!isEditable}
              className='name'
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='form-inside-flex'>
            <label>Opis:</label>
            <input
              type='text'
              value={stateDesc}
              disabled={!isEditable}
              className='name'
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>
          <div className='form-inside-flex'>
            <label>Cena male pice:</label>
            <input
              type='number'
              step='0.01'
              value={statePrice1}
              disabled={!isEditable}
              className='price'
              onChange={(e) => setPrice1(e.target.value)}
            />
          </div>
          <div className='form-inside-flex'>
            <label>Cena srednje pice:</label>
            <input
              type='number'
              step='0.01'
              value={statePrice2}
              disabled={!isEditable}
              className='price'
              onChange={(e) => setPrice2(e.target.value)}
            />
          </div>
          <div className='form-inside-flex'>
            <label>Cena velike pice::</label>
            <input
              type='number'
              step='0.01'
              value={statePrice3}
              disabled={!isEditable}
              className='price'
              onChange={(e) => setPrice3(e.target.value)}
            />
          </div>
          <div className='form-inside-flex'>
            <label>Zaporedna številka:</label>
            <input
              type='number'
              step='1'
              value={stateOrderNumber}
              disabled={!isEditable}
              className='order_number'
              onChange={(e) => setOrderNumber(e.target.value)}
            />
          </div>
          <div className='buttons'>
            {isEditable ? (
              <SaveIcon className='save-icon' onClick={updateElement} />
            ) : (
              <EditIcon
                className='edit-icon'
                onClick={() => setIsEditable(true)}
              />
            )}
            <DeleteForeverIcon
              onClick={() => showOnDelete()}
              className='delete-icon'
            />
          </div>
        </li>
      ) : (
        <li>
          <div className='form-inside-flex'>
            <label>Ime:</label>
            <input
              type='text'
              value={stateName}
              disabled={!isEditable}
              className='name'
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='form-inside-flex'>
            <label>Opis:</label>
            <input
              type='text'
              value={stateDesc}
              disabled={!isEditable}
              className='name'
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>
          <div className='form-inside-flex'>
            <label>Cena:</label>
            <input
              type='number'
              step='0.01'
              value={statePrice1}
              disabled={!isEditable}
              className='price'
              onChange={(e) => setPrice1(e.target.value)}
            />
          </div>
          <div className='form-inside-flex'>
            <label>Zaporedna številka:</label>
            <input
              type='number'
              step='1'
              value={stateOrderNumber}
              disabled={!isEditable}
              className='order_number'
              onChange={(e) => setOrderNumber(e.target.value)}
            />
          </div>
          <div className='buttons'>
            {isEditable ? (
              <SaveIcon className='save-icon' onClick={updateElement} />
            ) : (
              <EditIcon
                className='edit-icon'
                onClick={() => setIsEditable(true)}
              />
            )}
            <DeleteForeverIcon
              onClick={() => showOnDelete()}
              className='delete-icon'
            />
          </div>
        </li>
      )}
      {onDelete ? (
        <DeleteConfirmationProduct
          itemId={itemId}
          id={id}
          onDelete={onDelete}
          setOnDelete={setOnDelete}
        />
      ) : null}
    </>
  );
}

export default CollapsibleBox;
