import React, { useEffect, useState } from "react";
import food1 from "../assets/images/food1.png";
import food2 from "../assets/images/food2.png";
import food3 from "../assets/images/food3.png";
import HomepageSocials from "./HomepageSocials";
import MenuModal from "./modals/MenuModal";
import { NavLink } from "react-router-dom";

function Homepage() {
  const [showMenuModal, setMenuModal] = useState(false);

  const openMenuModal = () => {
    setMenuModal(!showMenuModal);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeImg = (image, color) => {
    document.querySelector(".homepage__bigImage").src = image;
    const circle = document.querySelector(".homepage__circle");
    circle.style.background = color;
  };

  return (
    <>
      <section className='homepage main-wrapper'>
        <div className='homepage__circle'></div>
        <div className='homepage__content'>
          <div className='homepage__textBox'>
            <h2>
              Okusna hrana,
              <br />
              hitra <span>postrežba</span>
            </h2>
            <button onClick={openMenuModal}>Jedilnik</button>
            <NavLink to='/meni'>Stalni meni</NavLink>
          </div>
          <div className='homepage__imgBox'>
            <img src={food1} alt='' className='homepage__bigImage' />
          </div>
        </div>
        <ul className='homepage__thumb'>
          <li>
            <img
              src={food1}
              alt='Predjed'
              onClick={() => changeImg(food1, "#d9cab3")}
            />
          </li>
          <li>
            <img
              src={food2}
              alt='Glavna jed'
              onClick={() => changeImg(food2, "#8c7a6b")}
            />
          </li>
          <li>
            <img
              src={food3}
              alt='Sladica'
              onClick={() => changeImg(food3, "#6a5354")}
            />
          </li>
        </ul>
        <HomepageSocials />
        {/* Inlcuding Modals */}
        <MenuModal showModal={showMenuModal} setShowModal={setMenuModal} />
      </section>
    </>
  );
}

export default Homepage;
