import React, { useEffect, useState } from "react";
import DailyFoodMenuItem from "./DailyFoodMenuItem";
import FoodMenuItem from "./FoodMenuItem";
import db from "../firebase";

function FoodMenu() {
  const [dailyItems, setDailyItems] = useState([]);

  const [startInitTag, setStartInitTag] = useState(false);
  const [items, setItems] = useState([]);
  const [tag, setTag] = useState("init");
  const [stateTitle, setStateTitle] = useState("Jedi po domače");
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    db.collection("dnevna_ponudba")
      .orderBy("order_number", "asc")
      .onSnapshot((snapshot) => {
        setDailyItems(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            title: doc.data().title,
            price: doc.data().price,
          }))
        );
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    db.collection("hrana")
      .orderBy("order_number", "asc")
      .onSnapshot((snapshot) => {
        setItems(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            title: doc.data().title,
          }))
        );
      });
    setTag("QOoIITIVrQp74BEjPvpB");
  }, []);

  useEffect(() => {
    db.collection("hrana")
      .doc(tag)
      .collection("izdelki")
      .orderBy("order_number", "asc")
      .onSnapshot((snapshot) => {
        setFilteredItems(snapshot.docs.map((doc) => doc.data()));
      });
  }, [tag]);

  const changeTag = (e, id, title) => {
    const arrayOfButtons = document.querySelectorAll(".filter");
    arrayOfButtons.forEach((button) => {
      button.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
    setTag(id);
    setStateTitle(title);
    setStartInitTag(true);
  };

  return (
    <section id='menu-list' className='section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 text-center marb-35'>
            <h1 className='header-h'>Menu List</h1>
          </div>
          <div className='col-md-12 mb-5'>
            {dailyItems.map(({ id, title, price }) => (
              <DailyFoodMenuItem key={id} name={title} price={price} />
            ))}
          </div>
          <div className='col-md-12 text-center' id='menu-filters'>
            <ul>
              {items.map(({ id, title }) => (
                <li key={id}>
                  <button
                    className='filter'
                    onClick={(e) => changeTag(e, id, title)}>
                    {title}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className='menu-wrapper'>
            {startInitTag ? (
              <>
                {filteredItems.map(
                  ({ name, description, price1, price2, price3 }) => (
                    <FoodMenuItem
                      key={name}
                      title={stateTitle}
                      name={name}
                      description={description}
                      price1={price1}
                      price2={price2}
                      price3={price3}
                    />
                  )
                )}
              </>
            ) : (
              <p className='startInitTag-text'>
                S pritiskom na zgornje gumbe izberite vrsto jedi, katero želite
                poskusiti. Dober tek 😊
              </p>
            )}
          </div>
          {/* End Menu Wrapper */}
        </div>
        {/* End Row */}
      </div>
    </section>
  );
}

export default FoodMenu;
