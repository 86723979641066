import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Header({ page, setPage }) {
  const animateNav = () => {
    const hamburger = document.querySelector(".hamburger");
    const navLinks = document.querySelector(".nav-links");
    const links = document.querySelectorAll(".nav-links li");

    hamburger.classList.toggle("open");
    navLinks.classList.toggle("open");
    links.forEach((link) => {
      link.classList.toggle("fade");
    });
  };

  const [isDesktop, setIsDesktop] = useState(false);

  const checkIfMobile = () => {
    if (window.innerWidth > 991) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth > 991) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", checkIfMobile);
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  return (
    <nav>
      <div className='hamburger' onClick={animateNav}>
        <div className='line line1'></div>
        <div className='line line2'></div>
        <div className='line line3'></div>
      </div>
      <Link to='/' id='nav-mobile'>
        Restavracija preša
      </Link>
      <ul className='nav-links'>
        <li>
          <Link to='/' onClick={isDesktop ? null : animateNav}>
            Domov
          </Link>
        </li>
        <li>
          <Link to='/kontakt' onClick={isDesktop ? null : animateNav}>
            Kontakt
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
