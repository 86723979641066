import React, { useRef, useEffect, useCallback, useState } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import db from "../../firebase";
import firebase from "firebase";
import { toast } from "react-toastify";

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9999;
`;

const ModalWrapper = styled.div`
  width: 100%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  position: relative;
  z-index: 10;
  width: 100%;
  @media (min-width: 500px) {
    border-radius: 10px;
  }
`;
function AddProduct({ itemId, title, showModal, setShowModal }) {
  const modalRef = useRef();

  const [stateName, setName] = useState("");
  const [stateDesc, setDesc] = useState("");
  const [statePrice1, setPrice1] = useState(0);
  const [statePrice2, setPrice2] = useState(0);
  const [statePrice3, setPrice3] = useState(0);
  const [stateOrderNumber, setOrderNumber] = useState(0);

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: 1,
    transform: `translateY(0%)`,
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  const addElement = (e) => {
    e.preventDefault();
    db.collection("hrana")
      .doc(itemId)
      .collection("izdelki")
      .add({
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        name: stateName,
        description: stateDesc,
        price1: statePrice1,
        price2: statePrice2,
        price3: statePrice3,
        order_number: stateOrderNumber,
      })
      .then(() => {
        toast.success("Izdelek uspešno dodan.");
      })
      .catch((error) => {
        toast.error(error);
      });
    setName("");
    setDesc("");
    setPrice1("");
    setPrice2("");
    setPrice3("");
    setOrderNumber("");
  };

  return (
    <>
      {showModal ? (
        <Background ref={modalRef} onClick={closeModal}>
          <animated.div className='opentime-animation' style={animation}>
            <ModalWrapper showModal={showModal}>
              <div className='content-modal add-product-modal'>
                <div className='m-header'>
                  <h5>{title}</h5>
                  <CloseIcon
                    aria-label='Close modal'
                    onClick={() => setShowModal(!showModal)}
                  />
                </div>
                <div className='m-body'>
                  <form onSubmit={addElement}>
                    <input
                      type='text'
                      className='text'
                      value={stateName}
                      placeholder='Izdelek'
                      onChange={(e) => setName(e.target.value)}
                    />
                    <input
                      type='text'
                      className='text'
                      value={stateDesc}
                      placeholder='Opis,sestavine,...'
                      onChange={(e) => setDesc(e.target.value)}
                    />
                    <div className='form-inside-flex'>
                      <label>Cena:</label>
                      <input
                        type='number'
                        step='0.01'
                        className='price'
                        value={statePrice1}
                        min='0'
                        onChange={(e) => setPrice1(e.target.value)}
                      />
                    </div>
                    <div className='form-inside-flex'>
                      <label>Cena srednje pice:</label>
                      <input
                        type='number'
                        step='0.01'
                        className='price'
                        value={statePrice2}
                        min='0'
                        onChange={(e) => setPrice2(e.target.value)}
                      />
                    </div>
                    <div className='form-inside-flex'>
                      <label>Cena velike pice:</label>
                      <input
                        type='number'
                        step='0.01'
                        className='price'
                        value={statePrice3}
                        min='0'
                        onChange={(e) => setPrice3(e.target.value)}
                      />
                    </div>
                    <div className='form-inside-flex'>
                      <label>Zaporedna številka:</label>
                      <input
                        type='number'
                        className='order_number'
                        value={stateOrderNumber}
                        min='0'
                        onChange={(e) => setOrderNumber(e.target.value)}
                      />
                    </div>
                    <button type='submit'>Dodaj</button>
                  </form>
                </div>
              </div>
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}

export default AddProduct;
