import React from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
function Location() {
  return (
    <MapContainer center={[46.511778, 15.0889759]} zoom={13}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <Marker position={[46.511778, 15.0889759]} />
    </MapContainer>
  );
}

export default Location;
