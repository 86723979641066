import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counterSlice";
import userReducer from "../features/userSlice";
import imageReducer from "../features/imageSlice";
import loadingReducer from "../features/loadingSlice";

export default configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    image: imageReducer,
    loading: loadingReducer,
  },
});
