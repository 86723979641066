import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAOV2b6pVl6NE2YQuhjNCbvNE_WahC_QtY",
  authDomain: "restavracijapresa-be902.firebaseapp.com",
  projectId: "restavracijapresa-be902",
  storageBucket: "restavracijapresa-be902.appspot.com",
  messagingSenderId: "686531367745",
  appId: "1:686531367745:web:0845c643f9a67ebaae87c0",
  measurementId: "G-KJM2FC7MCB",
});
// database
const db = firebaseApp.firestore();
// storage
const storage = firebase.storage();
//authentication
const auth = firebaseApp.auth();
// google auth
const provider = new firebase.auth.GoogleAuthProvider();

export { auth, provider, storage };
export default db;
