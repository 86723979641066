import React from 'react'
import FacebookIcon from '@material-ui/icons/Facebook';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

function HomepageSocials() {
    return (
        <ul className="homepage__sci">
            <li>
            <a href="mailto:rest.presa@gmail.com"><EmailIcon /></a>
            </li>
            <li>
            <a href="tel:(02)8844140"><PhoneIcon /></a>
            </li>
            <li>
            <a href="https://www.facebook.com/rest.presa/" target="_blank"><FacebookIcon /></a>
            </li>
        </ul>
    )
}

export default HomepageSocials
