import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "./components/Homepage";
import Contact from "./components/Contact";
import Header from "./components/layouts/Header";
import LoadingScreen from "./components/layouts/LoadingScreen";
import Footer from "./components/layouts/Footer";
import Login from "./components/admin/Login";
import { auth } from "./firebase";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, selectUser } from "./features/userSlice";
import { changeLoading } from "./features/loadingSlice";
import { selectLoading } from "./features/loadingSlice";
import Navbar from "./components/admin/Navbar";
import FoodMenu from "./components/FoodMenu";
import NotFound404 from "./components/404/NotFound404";

function App() {
  const isLoading = useSelector(selectLoading);
  const user = useSelector(selectUser);

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        dispatch(
          login({
            uid: authUser.uid,
            email: authUser.email,
          })
        );
      } else {
        dispatch(logout());
      }
    });
    return () => {
      //Any cleanup operations
      unsubscribe();
    };
  }, [dispatch]);

  useEffect(() => {
    setTimeout(function () {
      dispatch(changeLoading(false));
    }, 500);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Router>
          <Switch>
            <Route exact path='/admin'>
              {user ? <Navbar /> : <Login />}
            </Route>
            <Route exact path='/meni'>
              <Header />
              <FoodMenu />
              <Footer />
            </Route>
            <Route exact path='/kontakt'>
              <Header />
              <Contact />
              <Footer />
            </Route>
            <Route exact path='/'>
              <Header />
              <Homepage />
              <Footer />
            </Route>
            <Route path='*'>
              <div className='container'>
                <NotFound404 />
              </div>
            </Route>
          </Switch>
        </Router>
      )}
    </>
  );
}

export default App;
