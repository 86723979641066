import React, { useState, useEffect } from "react";
import JedilnikBox from "./JedilnikBox";
import db, { storage } from "../../firebase";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateImage } from "../../features/imageSlice";
import { getDefaultNormalizer } from "@testing-library/react";

function Jedilnik() {
  const dispatch = useDispatch();

  const [stateName, setName] = useState("");
  const [stateUrl, setUrl] = useState("");
  const [stateTime, setTime] = useState(null);
  const [onGetUrl, setOnGetUrl] = useState(false);
  const [onUpload, setOnUpload] = useState(false);
  const [items, setItems] = useState([]);
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const uploadMetadata = (name, url, time) => {
    db.collection("images")
      .add({
        name: name,
        url: url,
        created_at: time,
      })
      .then(() => {
        dispatch(updateImage(url));
        toast.success("Jedilnik uspešno dodan.");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    if (onUpload && onGetUrl) {
      uploadMetadata(stateName, stateUrl, stateTime);
      setOnGetUrl(false);
      setOnUpload(false);
    }
  });

  /*
  useEffect(() => {
    db.collection("images")
      .orderBy("created_at", "asc")
      .onSnapshot((snapshot) => {
        setItems(
          snapshot.docs.map((doc) => ({
            name: doc.data().name,
            url: doc.data().url,
            created_at: doc.data().created_at,
          }))
        );
      });
  }, []);
  */

  const handleImage = (e) => {
    e.preventDefault();
    if (image) {
      const uploadImage = storage.ref(`jedilniki/${image.name}`).put(image);
      uploadImage.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          alert(error.message);
        },
        () => {
          storage
            .ref("jedilniki")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              setUrl(url);
              setOnGetUrl(true);
            });
          storage
            .ref("jedilniki")
            .child(image.name)
            .getMetadata()
            .then(({ timeCreated, name }) => {
              setName(name);
              setTime(timeCreated);
              setOnUpload(true);
            });
          setImage(null);
          setProgress(0);
        }
      );
    }
  };

  return (
    <>
      <h2>Tedenski jedilnik</h2>
      <div className='row'>
        <div className='col-12 add-jedilnik-form-wrapper'>
          <form onSubmit={handleImage} className='form__jedilnik'>
            <input type='file' id='fileInput' onChange={handleFileChange} />
            <button type='submit' className='btn btn-primary'>
              Dodaj
            </button>
          </form>
          <progress max='100' value={progress} />
        </div>
      </div>
      {/*
        <h3>Vsi jedilniki</h3>
        <div className='col-12'>
          <div className='row table-wrapper'>
            {items.map((name, id) => (
              <JedilnikBox
                key={id}
                filename={name}
                setItems={setItems}
                items={items}
                index={id}
              />
            ))}
          </div>
        </div>
      */}
    </>
  );
}

export default Jedilnik;
