import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
import PageNotFound from "../../assets/images/404.jpg";

const NotFound404 = () => {
  return (
    <div id='NotFound404'>
      <div className='row'>
        <div className='col-md-6 content-404'>
          <h2>
            Strani, ki ste jo iskali, <span>žal ni mogoče najti.</span>
          </h2>
          <p>Lahko se vrnete na našo začetno stran ali nas kontaktirate.</p>
          <Link to='/'>
            <HomeIcon />
            Vrnite se na začetno stran{" "}
          </Link>
        </div>
        <div className='col-md-6 picture-404'>
          <img src={PageNotFound} alt='404 Page Not Found' />
        </div>
      </div>
    </div>
  );
};

export default NotFound404;
