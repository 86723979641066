import React from 'react';
import Logo from "../../assets/images/logo.png";
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

function LoadingScreen() {
    const classes = useStyles();
    return (
        <section className="loadingscreen">
            <img src={Logo} alt="Restavracija Preša" />
            <span>Samo trenutek...</span>
            <div className={classes.root}>
                <LinearProgress />
                <LinearProgress />
            </div>
        </section>
    )
}

export default LoadingScreen
