import React, { useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import { selectImage, updateImage } from "../../features/imageSlice";
import { useSelector, useDispatch } from "react-redux";
import db from "../../firebase";

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9999;
`;

const ModalWrapper = styled.div`
  width: 100%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  position: relative;
  z-index: 10;
  width: 100%;
  @media (min-width: 500px) {
    border-radius: 10px;
  }
`;
function MenuModal({ showModal, setShowModal }) {
  const modalRef = useRef();
  const latestImage = useSelector(selectImage);
  const dispatch = useDispatch();

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: 1,
    transform: `translateY(0%)`,
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    db.collection("images")
      .orderBy("created_at", "asc")
      .onSnapshot((snapshot) => {
        snapshot.docs.map((doc) => {
          dispatch(updateImage(doc.data().url));
        });
      });
  }, []);

  return (
    <>
      {showModal ? (
        <Background ref={modalRef} onClick={closeModal}>
          <animated.div className='menuModal-animation' style={animation}>
            <ModalWrapper showModal={showModal}>
              <div className='content-modal content-modal-image'>
                <div className='m-header'>
                  <h5>Jedilnik:</h5>
                  <CloseIcon
                    aria-label='Close modal'
                    onClick={() => setShowModal(!showModal)}
                  />
                </div>
                <div className='m-body'>
                  <img
                    src={latestImage}
                    alt='Napaka s povezavo. Ni mogoče naložiti slike.'
                  />
                </div>
              </div>
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}

export default MenuModal;
