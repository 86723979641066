import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";

function Footer() {
  const user = useSelector(selectUser);
  return (
    <footer>
      Copyright © 2021 Restavracija Preša
      <span className='author'>
        <a href='https://nejcrogelsek.si' target='_blank' rel='noreferrer'>
          Izvedba: Nejc Rogelšek
        </a>
      </span>
      <span className='goto-login'>
        <NavLink to='/admin'>
          {user ? <span>Admin</span> : <span>Prijava</span>}
        </NavLink>
      </span>
    </footer>
  );
}

export default Footer;
