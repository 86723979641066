import React, { useState, useEffect, useRef } from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import CollapsibleBox from "./CollapsibleBox";
import DeleteConfirmation from "./DeleteConfirmation";
import AddIcon from "@material-ui/icons/Add";
import { toast } from "react-toastify";
import db from "../../firebase";
import AddProduct from "../modals/AddProduct";

function Collapsible({ itemId, title }) {
  const parentRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [stateTitle, setTitle] = useState("");
  const [onDelete, setOnDelete] = useState(false);
  const [showAddProductModal, setAddProductModal] = useState(false);

  const [items, setItems] = useState([]);

  useEffect(() => {
    setTitle(title);
    if (itemId) {
      db.collection("hrana")
        .doc(itemId)
        .collection("izdelki")
        .orderBy("order_number", "asc")
        .onSnapshot((snapshot) => {
          setItems(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              name: doc.data().name,
              description: doc.data().description,
              price1: doc.data().price1,
              price2: doc.data().price2,
              price3: doc.data().price3,
              order_number: doc.data().order_number,
            }))
          );
        });
    }
  }, [title, itemId]);

  const updateElement = () => {
    console.log(items);
    db.collection("hrana")
      .doc(itemId)
      .update({
        title: stateTitle,
      })
      .then(() => {
        toast.success("Izdelek uspešno posodobljen.");
        setIsEditable(false);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const showOnDelete = () => {
    if (isEditable === true) {
      toast.warning(
        "Če želite ta izdelek izbrisati, morate najprej zaključiti z urejanjem."
      );
    } else {
      setOnDelete(true);
    }
  };

  return (
    <div className='col-12 collapsible-container'>
      <div className='collapsible'>
        <div className='info'>
          {isOpen ? (
            <CloseIcon
              className='close-icon'
              onClick={() => setIsOpen(false)}
            />
          ) : (
            <MoreVertIcon
              className='expand-icon'
              onClick={() => setIsOpen(true)}
            />
          )}
          <input
            type='text'
            value={stateTitle}
            disabled={!isEditable}
            onChange={(e) => setTitle(e.target.value)}
          />
          <AddIcon onClick={() => setAddProductModal(!showAddProductModal)} />
          {isEditable ? (
            <SaveIcon className='save-icon' onClick={updateElement} />
          ) : (
            <EditIcon
              className='edit-icon'
              onClick={() => setIsEditable(true)}
            />
          )}
          <DeleteForeverIcon
            onClick={() => showOnDelete()}
            className='delete-icon'
          />
        </div>
        <div
          className='content-parent'
          ref={parentRef}
          style={
            isOpen
              ? { height: parentRef.current.scrollHeight + "px" }
              : { height: "0px" }
          }>
          <div className='content'>
            <ul className='collapsible-list'>
              {items.map(
                ({
                  id,
                  name,
                  description,
                  price1,
                  price2,
                  price3,
                  order_number,
                }) => (
                  <CollapsibleBox
                    key={name}
                    id={id}
                    title={stateTitle}
                    name={name}
                    price1={price1}
                    price2={price2}
                    price3={price3}
                    desc={description}
                    order_number={order_number}
                    itemId={itemId}
                  />
                )
              )}
            </ul>
          </div>
        </div>
        {onDelete ? (
          <DeleteConfirmation
            id={itemId}
            onDelete={onDelete}
            setOnDelete={setOnDelete}
          />
        ) : null}
        <AddProduct
          showModal={showAddProductModal}
          setShowModal={setAddProductModal}
          title={title}
          itemId={itemId}
        />
      </div>
    </div>
  );
}

export default Collapsible;
